import { useState } from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { BsTelephone } from 'react-icons/bs';
import styled from 'styled-components';
export default function Footer() {
  const [year] = useState(new Date().getFullYear());
  return (
    <>
      <FooterContainer>
        <ul className="links">
          <li>
            <a href="#hero">בית</a>
          </li>
          <li>
            <a href="#services">אודות</a>
          </li>
          <li>
            <a href="#programs">תכניות וסדנאות</a>
          </li>
          <li>
            <a href="#testimonials">לקוחות מדברים</a>
          </li>
        </ul>
        <ul
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
          className="social__links"
        >
          <li>
            <a href="tel:0526966155" style={{ textDecoration: 'none' }}>
              0526966155
            </a>
            &nbsp;||&nbsp;
            <a href="tel:0526443985" style={{ textDecoration: 'none' }}>
              0526443985&nbsp;
            </a>
            <BsTelephone />
          </li>
          <li>
            info.edu.programs@gmail.com
            <AiOutlineMail />
          </li>
        </ul>
        <span style={{ textAlign: 'center', justifySelf: 'baseline' }}>
          כל הזכויות שמורים &copy; {year} ל- Steps خُطوات
        </span>
      </FooterContainer>
    </>
  );
}

const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  background-color: #d0d8ff;
  border-radius: 0.5rem;
  padding: 2.5rem 2.5rem 1rem 2.5rem;

  ul {
    display: flex;
    list-style-type: none;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    li {
      a {
        text-decoration: none;
        color: black;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #302ce9;
        }
      }
      svg {
        font-size: 1.3rem;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #302ce9;
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1024px) {
    flex-direction: column;
    gap: 2rem;
    ul {
      flex-direction: column;
    }
    .social__links {
      flex-direction: row;
    }
  }
`;
