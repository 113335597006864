import { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { VscChromeClose } from 'react-icons/vsc';
import styled from 'styled-components';
import logo from '../assets/icon/favicon.png';
export default function Navbar() {
  const [navbarState, setNavbarState] = useState(false);
  return (
    <>
      <Nav>
        <div className="brand">
          <div className="container">
            <img src={logo} alt="" />
            Steps
          </div>
          <div className="toggle">
            {navbarState ? (
              <VscChromeClose onClick={() => setNavbarState(false)} />
            ) : (
              <GiHamburgerMenu
                style={{ fontSize: 'larger' }}
                onClick={() => setNavbarState(true)}
              />
            )}
          </div>
        </div>

        <ul>
          <li>
            <a href="#home">בית</a>
          </li>
          <li>
            <a href="#services">אודות</a>
          </li>
          <li>
            <a href="#programs">תכניות וסדנאות</a>
          </li>
          {/* <li>
            <a href="#testimonials">לקוחות מדברים</a>
          </li> */}
        </ul>
        {/* <Stack direction="row"> */}
        <button
          onClick={() => {
            document.getElementsByName('email')[0].focus();
            document
              .getElementById('hero')
              .scrollIntoView({ behavior: 'smooth' });
          }}
        >
          צור קשר
        </button>
        {/* <Typography component="a" href="tel:0526966155">
            0526966155
          </Typography>
        </Stack> */}
      </Nav>
      <ResponsiveNav state={navbarState}>
        <ul>
          <li>
            <a href="#home" onClick={() => setNavbarState(false)}>
              בית
            </a>
          </li>
          <li>
            <a href="#services" onClick={() => setNavbarState(false)}>
              אודות
            </a>
          </li>
          <li>
            <a href="#programs" onClick={() => setNavbarState(false)}>
              תכניות וסדנאות
            </a>
          </li>
          {/* <li>
            <a href="#testimonials" onClick={() => setNavbarState(false)}>
              לקוחות מדברים
            </a>
          </li> */}
        </ul>
      </ResponsiveNav>
    </>
  );
}

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .brand {
    .container {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.4rem;
      font-size: 1.2rem;
      font-weight: 900;
      text-transform: uppercase;
    }
    .toggle {
      display: none;
    }
  }
  ul {
    display: flex;
    gap: 1rem;
    list-style-type: none;
    li {
      a {
        text-decoration: none;
        color: #0077b6;
        font-size: 1.2rem;
        transition: 0.1s ease-in-out;
        &:hover {
          color: #023e8a;
        }
      }
      &:first-of-type {
        a {
          color: #023e8a;
          font-weight: 900;
        }
      }
    }
  }
  button {
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 1rem;
    border: none;
    color: white;
    background-color: #48cae4;
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    transition: 0.3s ease-in-out;
    &:hover {
      background-color: #023e8a;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    .brand {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .toggle {
        display: block;
      }
    }
    ul {
      display: none;
    }
    button {
      display: none;
    }
  }
`;

const ResponsiveNav = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  top: ${({ state }) => (state ? '50px' : '-400px')};
  background-color: white;
  height: 30vh;
  width: 100%;
  align-items: center;
  transition: 0.3s ease-in-out;
  ul {
    list-style-type: none;
    width: 100%;
    li {
      width: 100%;
      margin: 1rem 0;
      margin-left: 2rem;

      a {
        text-decoration: none;
        color: #0077b6;
        font-size: 1.2rem;
        transition: 0.1s ease-in-out;
        &:hover {
          color: #023e8a;
        }
      }
      &:first-of-type {
        a {
          color: #023e8a;
          font-weight: 900;
        }
      }
    }
  }
`;
