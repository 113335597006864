import { CallOutlined, EmailOutlined } from '@mui/icons-material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  Avatar,
  Button,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import programsData from '../mocks/programsData';
import availableSkills from '../mocks/skills';
import './programs.css';

const packages = [
  {
    title: 'all',
    label: 'כל התכניות',
  },
  {
    title: 'workshop',
    label: 'סדנאות והדרכות',
  },
  {
    title: 'educational',
    label: 'תכניות חינוכיות',
  },
  {
    title: 'treatments',
    label: 'תכניות טיפוליות',
  },
];
const supportEmail = 'info.edu.programs@gmail.com';
export default function Programs() {
  const [displayedData, setDisplayedData] = useState(programsData);
  const [active, setActive] = useState(1);
  const [programDialogOpen, setProgramDialogOpen] = useState({ state: false });
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (active > 1) {
      const temp = programsData.filter((el) =>
        el.package.includes(packages[active - 1].title)
      );
      setDisplayedData(temp);
    } else {
      setDisplayedData(programsData);
    }
  }, [active]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const searchQueryPId = Number(params.get('pid'));
    const index = programsData.findIndex(
      (program) => program.eduMinistryId === searchQueryPId
    );
    if (index > -1) {
      setProgramDialogOpen({ state: true, index });
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }, [isCopied]);
  return (
    <Section id="programs">
      <div className="title">
        <h2>התכניות שלנו</h2>
      </div>
      <div className="packages">
        <ul>
          {packages.map((pkg, index) => {
            return (
              <li
                className={active === index + 1 ? 'active' : ''}
                style={{ cursor: 'pointer' }}
                onClick={() => setActive(index + 1)}
                key={pkg.title}
              >
                {pkg.label}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="destinations">
        {displayedData.map((program, index) => {
          return (
            <div
              onClick={() => {
                setProgramDialogOpen({ state: true, index });
              }}
              className="destination box"
              style={{ cursor: 'pointer' }}
              key={program.eduMinistryId}
            >
              <img
                loading="lazy"
                style={{ borderRadius: '8px' }}
                src={program.image[0]}
                alt=""
              />
              {program.suitableFor && (
                <div className="ribbon ribbon-top-left">
                  <span>{`גם ל-${program.suitableFor.join(', ')}`}</span>
                </div>
              )}
              <h3>{program.title}</h3>
              <p>{program.subTitle}</p>
              <div className="info">
                <div className="services">
                  {program.skills.map((skill) => {
                    const t = availableSkills.findIndex(
                      (el) => el.title === skill
                    );
                    const SkillIcon = availableSkills[t].icon;
                    return (
                      <Tooltip key={skill} title={skill}>
                        <Avatar
                          sx={{
                            width: 32,
                            height: 32,
                            backgroundColor: '#8338ec50',
                          }}
                        >
                          {SkillIcon}
                        </Avatar>
                      </Tooltip>
                    );
                  })}
                  {/* <img src={info1} alt="" />
                  <img src={info2} alt="" />
                  <img src={info3} alt="" /> */}
                </div>
                <h4>{program.duration}</h4>
              </div>
              {/* <div className="distance">
                <span>1000 Kms</span>
                <span>{destination.duration}</span>
              </div> */}
            </div>
          );
        })}
      </div>
      <Dialog
        fullWidth
        // fullScreen={{ xs: true, md: false, lg: false, xl: false, sm: false }}
        maxWidth="md"
        onClose={() => setProgramDialogOpen({ state: false })}
        open={programDialogOpen.state}
      >
        {programDialogOpen.index >= 0 && (
          <>
            <DialogTitle color="primary">
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="column">
                  {displayedData[programDialogOpen.index].title}
                  <Typography variant="caption">
                    מס' תכנית במשרד החינוך{' '}
                    {displayedData[programDialogOpen.index].eduMinistryId}
                  </Typography>
                </Stack>
                <IconButton
                  // color="primary"
                  onClick={() => setProgramDialogOpen({ state: false })}
                >
                  <Icon>
                    <CloseOutlinedIcon />
                  </Icon>
                </IconButton>
              </Stack>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Stack
                direction={{ xs: 'column', sm: 'row', md: 'row' }}
                alignContent="space-between"
                // justifyContent="space-between"
                // // alignItems="center"
              >
                <Stack sx={{ flex: 1, m: 1 }} justifyContent="space-between">
                  <Stack>
                    <Typography
                      color="secondary"
                      variant="body1"
                      fontWeight="bold"
                    >
                      מטרת התכנית
                    </Typography>
                    <Typography variant="body2">
                      {displayedData[programDialogOpen.index].subTitle}
                    </Typography>
                  </Stack>
                  <Divider sx={{ my: 1 }} />
                  <Stack>
                    <Typography
                      color="secondary"
                      variant="body1"
                      fontWeight="bold"
                    >
                      תמצית התכנית
                    </Typography>
                    <Typography
                      sx={{ maxHeight: '20vh', overflowY: 'auto' }}
                      variant="body2"
                    >
                      {displayedData[programDialogOpen.index].summary}
                    </Typography>
                  </Stack>
                  <Stack
                    mt={2}
                    direction="row"
                    justifyContent="flex-start"
                    gap={2}
                    sx={{ flexWrap: 'wrap' }}
                  >
                    {displayedData[programDialogOpen.index].skills.map(
                      (skill) => (
                        <Chip
                          color="secondary"
                          variant="outlined"
                          label={skill}
                          key={skill}
                        />
                      )
                    )}
                  </Stack>
                </Stack>
                <CardMedia
                  // image=""
                  component="img"
                  alt={displayedData[programDialogOpen.index].title}
                  src={displayedData[programDialogOpen.index].image[0]}
                  sx={{
                    width: { xs: '70%', md: '40%', sm: '55%' },
                    height: { xs: '70%', md: '40%', sm: '55%' },
                    borderRadius: '8px',
                  }}
                  // width="40%"
                  // height="40%"
                  loading="lazy"
                />
              </Stack>
              <Divider />
              <Typography variant="body2">עדיין יש שאלות?</Typography>
              <Stack
                direction={{ md: 'row', xs: 'column' }}
                gap={2}
                alignItems="center"
                justifyContent="center"
              >
                {/* <Typography variant="body2">
                  אנו זמינים בטלפון ובמייל
                </Typography> */}
                <CallOutlined />
                <Button sx={{ alignItems: 'center', display: 'flex' }}>
                  <Typography
                    href="tel:0526966155"
                    style={{ textDecoration: 'none' }}
                    component="a"
                  >
                    0526966155
                  </Typography>
                </Button>
                <Button sx={{ alignItems: 'center', display: 'flex' }}>
                  <Typography
                    href="tel:0526443985"
                    style={{ textDecoration: 'none' }}
                    component="a"
                  >
                    0526443985
                  </Typography>
                </Button>
                <Stack direction="row">
                  <EmailOutlined />
                  <Tooltip arrow open={isCopied} title="הועתק ללוח">
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(supportEmail);
                        setIsCopied(true);
                      }}
                    >
                      <Typography
                        style={{ textTransform: 'initial' }}
                        component="a"
                        value={supportEmail}
                      >
                        {supportEmail}
                      </Typography>
                    </Button>
                  </Tooltip>
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions sx={{ mx: 2.5 }}>
              <Button
                variant="outlined"
                onClick={() => setProgramDialogOpen({ state: false })}
              >
                סגירה
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Section>
  );
}

const Section = styled.section`
  padding: 2rem 0;
  .title {
    text-align: center;
  }
  .packages {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
    padding: 1rem;
    ul {
      display: flex;
      list-style-type: none;
      width: max-content;
      li {
        padding: 1rem 2rem;
        border-bottom: 0.1rem solid black;
        &:hover {
          background-color: #8338ec40;
        }
      }
      .active {
        border-bottom: 0.5rem solid #8338ec;
      }
    }
  }
  .listItem {
    cursor: 'pointer';
  }
  .destinations {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    padding: 1rem 3rem;
    height: 100vh;
    overflow-y: auto;
    .destination {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      justify-content: space-between;
      background-color: #8338ec14;
      border-radius: 1rem;
      transition: 0.3s ease-in-out;
      &:hover {
        transform: translateX(0.4rem) translateY(-0.5rem);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
      img {
        width: 100%;
        max-height: 50%;
      }
      .info {
        display: flex;
        align-items: center;
        .services {
          display: flex;
          gap: 0.3rem;
          img {
            border-radius: 1rem;
            width: 2rem;
            padding: 0.3rem 0.4rem;
          }
        }
        display: flex;
        justify-content: space-between;
      }
      .distance {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  @media screen and (max-width: 280) {
    .destinations {
      grid-template-columns: 90%;
      padding: 0;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 768px) {
    .packages {
      ul {
        li {
          padding: 0 0.5rem;
          font-size: 2vh;
          padding-bottom: 1rem;
        }
        .active {
          border-bottom-width: 0.3rem;
        }
      }
    }
    .destinations {
      grid-template-columns: 1fr;
      padding: 0;
    }
  }
`;
