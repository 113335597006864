import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import CameraFrontOutlinedIcon from "@mui/icons-material/CameraFrontOutlined";
import ConnectWithoutContactOutlinedIcon from "@mui/icons-material/ConnectWithoutContactOutlined";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import HealthAndSafetyOutlinedIcon from "@mui/icons-material/HealthAndSafetyOutlined";
import LaptopChromebookOutlinedIcon from "@mui/icons-material/LaptopChromebookOutlined";
import PermScanWifiOutlinedIcon from "@mui/icons-material/PermScanWifiOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import ScienceOutlinedIcon from "@mui/icons-material/ScienceOutlined";
import SelfImprovementOutlinedIcon from "@mui/icons-material/SelfImprovementOutlined";
import SocialDistanceOutlinedIcon from "@mui/icons-material/SocialDistanceOutlined";
import TranslateOutlinedIcon from "@mui/icons-material/TranslateOutlined";

const availabeSkills = [
  {
    title: "אוריינות לשונית",
    icon: <TranslateOutlinedIcon />,
  },
  {
    title: "אוריינות מתמטית",
    icon: <CalculateOutlinedIcon />,
  },
  {
    title: "אוריינות מדעית",
    icon: <ScienceOutlinedIcon />,
  },
  {
    title: "חשיבה ביקורתית",
    icon: <PsychologyOutlinedIcon />,
  },
  {
    title: "חשיבה יצירתית",
    icon: <EmojiObjectsOutlinedIcon />,
  },
  {
    title: "אוריינות דיגיטלית",
    icon: <LaptopChromebookOutlinedIcon />,
  },
  {
    title: "אוריינות מידע",
    icon: <PermScanWifiOutlinedIcon />,
  },
  {
    title: "מודעות עצמית",
    icon: <SelfImprovementOutlinedIcon />,
  },
  {
    title: "הכוונה עצמית",
    icon: <CameraFrontOutlinedIcon />,
  },
  {
    title: "מודעות חברתית",
    icon: <ConnectWithoutContactOutlinedIcon />,
  },
  {
    title: "התנהלות חברתית",
    icon: <SocialDistanceOutlinedIcon />,
  },
  {
    title: "אוריינות גלובלית",
    icon: <PublicOutlinedIcon />,
  },
  {
    title: "אוריינות גופנית בריאותית",
    icon: <HealthAndSafetyOutlinedIcon />,
  },
];

export default availabeSkills;
