const programsData = [
  {
    image: [
      'https://images.unsplash.com/photo-1580927752452-89d86da3fa0a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Y29kaW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    ],
    title: 'ההייטקיסט הצעיר',
    subTitle:
      'לקרב את התלמידים מעולם הטכנולוגיה ואתרי האינטרנט ולהקנות היכרות ראשונית לעולם הזה בין אם זה ללמוד איך אתר עובד או ללמוד שפות פיתוח אתרי אינטרנט כמו HTML ו-CSS',
    cost: '38,800',
    duration: '11 מפגשים',
    package: ['educational', 'workshop'],
    summary:
      'תכנית שמקנה ידע לתלמידים, בעולם האתרים ופיתוח האתרים אשר תעזור להם לפתח חשיבה ביקורתית ופתרון בעיות ותקרב אותם מעולם הטכנולוגיה והעבודה האמיתית שנעשית בשוק העבודה בהייטק. במהלך התכנית, המרצה ישתמש בתוכנות וכלים דיגיטליים חינמיים.',
    skills: ['חשיבה יצירתית', 'חשיבה ביקורתית', 'אוריינות דיגיטלית'],
    id: 1,
    eduMinistryId: 20329,
  },
  {
    image: [
      'https://images.unsplash.com/photo-1603354350317-6f7aaa5911c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
    ],
    title: 'הוראה מותאמת אישית',
    subTitle:
      'לשפר את מיומנויות התלמידים במקצועות רבי מלל וחשבון-מתמטיקה ושימוש באסטרטגיות למידה יעילות',
    cost: '54,200',
    duration: '11 מפגשים',
    package: ['educational', 'treatments'],
    summary:
      'התכנית "הוראה מותאמת" מיועדת לתלמידים עם קשיי למידה במקצועות רבי מלל ובחשבון ומתמטיקה. תחומי ההתערבות הם: קריאה, הבנת הנקרא והבעה בכתב בנוסף לחשבון. על ידי איתור ראשוני במיוניות אקדמיות ומנגנונים קוגניטיביים, אפשר לעקוב אחרי נקודות חוזק ונקודות לחיזוק, לבנות תכנית התערבות מותאמת לקבוצה, והתחלת התערבות על ידי מאבחנת ומומחה בתחום הוראה מתקנת. חשוב לשים לב לתפקודי לומד ולסגנונות למידה של כל תלמיד, ליישם את SEL תוך כדי למידה בנוסף להדגיש על פסיכופדגוגיה בכדי להפיק למידה יעילה. בנוסף, נעשה סיכום והמלצות לכל תלמיד בסוף התכנית',
    skills: ['אוריינות מתמטית', 'אוריינות לשונית'],
    suitableFor: ['הורים'],
    eduMinistryId: 20352,
    id: 2,
  },
  {
    image: [
      'https://images.unsplash.com/photo-1557801200-9a8d901ded2a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fGdyYWR1YXRpb258ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    ],
    title: 'שמיים הם הגבול',
    subTitle:
      'הקניית ידע להורים ולמורים אודות לקויות למידה, איתור תלמידים עם לקות למידה, הקניית אסטרטגיות למידה',
    cost: '45,500',
    duration: '11 מפגשים',
    package: ['educational', 'treatments'],
    summary:
      'סדנאות למורים והורים בתחומים: 1) הדרכה בתחום הקשב והריכוז: איך להתמודד עם תלמידים עם הנמכה בתחום הקשב 2) אסטרטגיות למידה: אסטרטגיות במקצועות רבי מלל. 3) הדרכה על מהות לקות למידה ואיך לאתר תלמידים עם לקויות למידה 4) הדרכה בתחום תפקודי לומד במאה ה21, עם דגש על פסיכופדגוגיה ולמידה רגשית חברתית',
    skills: ['אוריינות לשונית', 'אוריינות מתמטית'],
    suitableFor: ['מורים', 'הורים'],
    id: 3,
    eduMinistryId: 20353,
  },
  {
    image: [
      'https://images.unsplash.com/photo-1530825894095-9c184b068fcb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW50ZXJhY3RpdmV8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    ],
    title: 'עולם של אינטראקטיביות',
    subTitle:
      'תכנית זו מטרתה לשפר את יכולות התלמידים כגון מוטוריקה גסה, מהירות תגובה, דיוק ותיאום עין יד - תנועת שרירי היד לבין תנועת שרירי העין בהתאם למידע החזותי שמתקבל',
    cost: '24,100',
    duration: 'מותאם אישית',
    package: ['educational', 'workshop'],
    summary:
      'פעילות סדנאית אשר תעזור ותשפר עבור התלמידים את היכולות כגון, מהירות ומהירות תגובה, תיאום עין יד, שיתופי פעולה בין התלמידים, העלאת הסקרנות בכל הקשור לטכנולוגיה ועולם המשחקים האינטראקטיביים, משחקי קיר אינטראקטיביים, משחקי רצפה ולייזר אינטראקטיביים.',
    skills: ['אוריינות מדעית', 'התנהלות חברתית', 'אוריינות דיגיטלית'],
    id: 4,
    eduMinistryId: 20354,
  },
  {
    image: [
      'https://images.unsplash.com/photo-1620148222862-b95cf7405a7b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHRoZXJhcHl8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    ],
    title: 'טיפול וריפוי',
    subTitle:
      'שיפור תהליכי ההוראה-למידה בתכנית פרטנית או קבוצתית. בניית תכנית התערבות רב מערכתית שתמצא את הפוטנציאל של התלמיד המתקשים בבתי הספר, הדרכת הצוותים',
    cost: '95,400',
    duration: 'מותאם אישית',
    package: ['treatments', 'workshop'],
    summary:
      'במסגרת תכנית זו ניתן טיפולים באופן פרטני או קבוצתי עבור ילדים עם קשיים בתחום המוטורי, הלימודי, תחושתי, קשיי כתיבה וקשיי התארגנות. במסגרת התכנית תינתן הדרכה והעשרה לצוות החינוכי ולהורים להבטחת המשכיות היישום במסגרת הבית והכיתה. ניתן לקבל בנפרד/לשלב בין מגוון טיפולים ולפי הצורך - ניתן להתאמה לכל ארגון בנפרד, כגון: 1) ריפוי בעיסוק 2) תרפיה באומנות 3) טיפול במשחק 4) קלינאיות תקשורת',
    skills: ['אוריינות מדעית', 'התנהלות חברתית', 'אוריינות דיגיטלית'],
    eduMinistryId: 20370,
    id: 5,
  },
];

export default programsData;
