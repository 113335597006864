import { useEffect } from 'react';
import scrollreveal from 'scrollreveal';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Programs from './components/Programs';
import ScrollToTop from './components/ScrollToTop';
import Services from './components/Services';
export default function App() {
  useEffect(() => {
    const sr = scrollreveal({
      origin: 'top',
      distance: '10px',
      duration: 1000,
      reset: false,
      scale: 0.8,
    });
    sr.reveal(
      `
        #hero,
        #services,
        #programs,
        #testimonials
        `,
      {
        opacity: 0,
        interval: 300,
      }
    );
  }, []);
  return (
    <div>
      <ScrollToTop />
      <Navbar />
      <Hero />
      <Services />
      <Programs />
      {/* <Testimonials /> */}
      <Footer />
    </div>
  );
}
