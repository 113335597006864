import styled from 'styled-components';
import service1 from '../assets/service1.png';
import service2 from '../assets/service2.png';
import service4 from '../assets/service4.png';
import service5 from '../assets/service5.png';

export default function Services() {
  const data = [
    {
      icon: service1,
      title: 'עלות מול תועלת',
      subTitle: 'כל ארגון יקבל תכנית מותאמת אשר תאפשר לו להפיק מקסימום תועלת',
    },
    {
      icon: service2,
      title: 'בהתאם לתו הסגול',
      subTitle:
        'כל הפעילויות יהיו בכפוף להנחיות ולהגבלות של משרד הבריאות ולתו הסגול',
    },
    {
      icon: service5,
      title: 'גמישות בתוכן',
      subTitle: 'תמיד יש אפשרות להתאים תכנית או אפילו חלק ממנה',
    },
    {
      icon: service4,
      title: 'אתה לא לבד',
      subTitle: 'ליווי לאורך כל הדרך, ייעוץ ועזרה בהצבת אבני המשך',
    },
  ];
  return (
    <Section id="services">
      {data.map((service) => {
        return (
          <div key={service.title} className="service">
            <div className="icon">
              <img src={service.icon} alt="" />
            </div>
            <h3>{service.title}</h3>
            <p>{service.subTitle}</p>
          </div>
        );
      })}
    </Section>
  );
}

const Section = styled.section`
  padding: 5rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  .service {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    background-color: aliceblue;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;
    transition: 0.3s ease-in-out;
    &:hover {
      transform: translateX(0.4rem) translateY(-1rem);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .icon {
      img {
        height: 2.4rem;
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 720px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
