import { CheckOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { db } from '../_firebase';

const initState = { phone: '', email: '', city: '' };
export default function Hero() {
  const [newLeadDetails, setNewLeadDetails] = useState(initState);
  const [successfulySaved, setSuccess] = useState(false);
  const [missingField, setMissingField] = useState({});

  const handleChange = (e) => {
    setNewLeadDetails({ ...newLeadDetails, [e.target.name]: e.target.value });
  };
  const handleSaveLeadDetails = () => {
    if (
      (newLeadDetails.phone && newLeadDetails.email) ||
      (newLeadDetails.phone && newLeadDetails.email && newLeadDetails.city)
    ) {
      setMissingField({ isMissing: false });
      const leadsRef = collection(db, 'leads');
      const mailRef = collection(db, 'mail');
      addDoc(leadsRef, newLeadDetails)
        .then(async () => {
          setNewLeadDetails(initState);
          setSuccess(true);
          await addDoc(mailRef, {
            to: ['info.edu.programs@gmail.com'],
            message: {
              subject: 'לקוח חדש השאיר פרטים',
              html: `
              <table>
  <thead>
    <tr>
      <th>Email</th>
      <th>Phone</th>
      <th>City</th>
      <th>Date</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td data-column="email">${newLeadDetails.email}</td>
      <td data-column="phone">${newLeadDetails.phone}</td>
      <td data-column="city">${newLeadDetails.city || ''}</td>
      <td data-column="date">${Timestamp.now().toDate()}</td>
    </tr>
  </tbody>
</table>
<style>
table { 
	width: 750px; 
	border-collapse: collapse; 
	margin:50px auto;
	}

/* Zebra striping */
tr:nth-of-type(odd) { 
	background: #eee; 
	}

th { 
	background: #3498db; 
	color: white; 
	font-weight: bold; 
	}

td, th { 
	padding: 10px; 
	border: 1px solid #ccc; 
	text-align: left; 
	font-size: 18px;
	}

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	table { 
	  	width: 100%; 
	}

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 1px solid #ccc; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}

	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
		/* Label the data */
		content: attr(data-column);

		color: #000;
		font-weight: bold;
	}

}
</style>`,
            },
          });
        })
        .catch((err) => console.log('err', err));
    }
  };

  useEffect(() => {
    if (successfulySaved) {
      setTimeout(() => {
        setSuccess(false);
      }, 1500);
    }
  }, [successfulySaved]);
  return (
    <Section id="hero">
      <div className="background">
        <img
          src="https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-1.2.1"
          alt=""
        />
      </div>
      <div className="content">
        <div className="title">
          <h1>תכניות לכולם</h1>
          <p>תכניות וסדנאות גמישות אשר מותאמות לפי הצרכים של כל ארגון</p>
        </div>
        <div className="search">
          <div className="container">
            <label
              onClick={() => document.getElementsByName('email')[0].focus()}
              htmlFor="email"
            >
              דוא"ל
            </label>
            <input
              onChange={handleChange}
              type="text"
              name="email"
              placeholder='כתובת דוא"ל'
              className={
                missingField.isMissing && missingField.name === 'email'
                  ? 'submitError'
                  : ''
              }
              value={newLeadDetails.email}
            />
          </div>
          <div className="container">
            <label
              onClick={() => document.getElementsByName('phone')[0].focus()}
              htmlFor="phone"
            >
              טלפון
            </label>
            <input
              onChange={handleChange}
              name="phone"
              type="text"
              className={
                missingField.isMissing && missingField.name === 'phone'
                  ? 'submitError'
                  : ''
              }
              placeholder="נרים לך טלפון"
              value={newLeadDetails.phone}
            />
          </div>
          <div className="container">
            <label
              onClick={() => document.getElementsByName('city')[0].focus()}
              htmlFor="city"
            >
              עיר
            </label>
            <input
              onChange={handleChange}
              name="city"
              type="text"
              placeholder="מהיכן אתה?"
              className={
                missingField.isMissing && missingField.name === 'city'
                  ? 'submitError'
                  : ''
              }
              value={newLeadDetails.city}
            />
          </div>
          <Button onClick={handleSaveLeadDetails}>
            {!successfulySaved ? (
              'דברו איתי'
            ) : (
              <>
                <CheckOutlined />
                נשלח
              </>
            )}
          </Button>
        </div>
      </div>
    </Section>
  );
}

const Section = styled.section`
  position: relative;
  margin-top: 2rem;
  width: 100%;
  height: 80vh;

  .background {
    height: 100%;
    img {
      width: 100%;
      filter: brightness(60%);
      border-radius: 8px;
      height: inherit;
    }
  }
  .content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    text-align: center;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    margin-top: 25vh;
    align-items: center;
    gap: 1rem;
    .title {
      color: white;
      h1 {
        font-size: 3rem;
        letter-spacing: 0.2rem;
      }
      p {
        text-align: center;
        padding: 0 30vw;
        margin-top: 0.5rem;
        font-size: 1.2rem;
      }
    }
    .search {
      display: flex;
      background-color: #ffffffce;
      padding: 0.5rem;
      border-radius: 0.5rem;
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 1.5rem;
        label {
          font-size: 1.1rem;
          color: #03045e;
        }
        .error {
          border: 1rem solid red;
        }
        input {
          background-color: transparent;
          border: none;
          text-align: center;
          color: black;

          &::placeholder {
            color: black;
          }
          &:focus {
            outline: none;
          }
        }
      }
      button {
        padding: 1rem;
        cursor: pointer;
        border-radius: 0.3rem;
        border: none;
        color: white;
        background-color: #4361ee;
        font-size: 1.1rem;
        text-transform: uppercase;
        transition: 0.3s ease-in-out;
        &:hover {
          background-color: #023e8a;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .content {
      margin-top: 10vh;
    }
    .search {
      width: 90vw;
    }
  }
  @media screen and (max-width: 980px) {
    height: 25rem;
    .background {
      background-color: palegreen;
      img {
        height: 100%;
      }
    }
    .content {
      margin-top: 10vh;
      .title {
        h1 {
          font-size: 1rem;
        }
        p {
          font-size: 0.8rem;
          padding: 1vw;
        }
      }
      .search {
        flex-direction: column;
        padding: 0.8rem;
        gap: 0.8rem;
        width: 60vw;
        /* padding: 0; */
        .container {
          padding: 0 0.8rem;
        }
        button {
          padding: 1rem;
          font-size: 1rem;
        }
        /* display: none; */
      }
    }
  }
`;
